import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'
import SEO from 'components/Seo'
import Button from 'components/widgets/Button'
import AnimateLink from 'components/widgets/AnimateLink'
import { pageView } from 'utils/tracker'
import { isBrowserIE } from 'utils/browser'
import { LANG_EN, TRACKING } from 'config/constants/common'
import './index.scss'

export default function Welcome() {
  const { t, i18n } = useTranslation(['welcome', 'home', 'login'])
  const { language: currentLang = LANG_EN } = i18n || {}

  const trackingEventSource = TRACKING.SOURCE.WELCOME
  useEffect(() => {
    pageView(trackingEventSource)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`Welcome-screen Layout-basic Layout-children ${isBrowserIE() && 'ie'}`}>
      <SEO title={`${t('lbl_welcome_en')}`} />
      <Container className={`Welcome-box ${currentLang}`}>
        <div className="Welcome-container">
          <div className="Welcome-content">
            <h4 className="Welcome-company">{t('home:lbl_title')}</h4>
            <p className="Welcome-slogan">{t('home:lbl_slogan_en')}</p>
            <p className="Welcome-label">{t('lbl_intro_title')}</p>
            <p
              className="Welcome-desc pc-view"
              dangerouslySetInnerHTML={{ __html: t('lbl_intro_desc') }}
            />
            <p className="Welcome-terms" dangerouslySetInnerHTML={{ __html: t('lbl_term') }} />
          </div>
          <div className="Welcome-footer">
            <AnimateLink to="/">
              <Button label={t('btn_go_home_en')} className="Welcome-btn" />
            </AnimateLink>
          </div>
        </div>
      </Container>
    </div>
  )
}
