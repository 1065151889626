/* eslint-disable quotes */
import ApiRequest from 'services/api'
import { TRACKING } from 'config/constants/common'
import { API_ADD_TRACKING } from 'config/api'

let previousUrl = ''
let currentUrl = ''

export const pageView = (source, params) => {
  previousUrl = currentUrl
  currentUrl = window.location.pathname
  collect('pageview', { eventSource: source, ...params })
}

export const pageEvent = (event_source, event_value) => {
  const params = {
    eventType: getEventType(event_value),
    eventSource: event_source,
    eventValue: event_value,
  }
  collect('event', params)
}

const getEventType = (event_value = '') => {
  if (event_value.includes('zoom')) return `event-${TRACKING.ACTION.ZOOM}`
  if (event_value.includes('audio')) return `event-${TRACKING.ACTION.LISTEN}`
  if (event_value.includes('video')) return `event-${TRACKING.ACTION.WATCH}`
  if (event_value.includes('download')) return `event-${TRACKING.ACTION.DOWNLOAD}`
  if (event_value.includes('add')) return `event-${TRACKING.ACTION.SAVE}`
}

// Collect metrics
const collect = (type, params) => {
  const payload = {
    eventType: type,
    eventSource: '',
    eventValue: '',
    eventUrl: currentUrl,
    eventReferrer: previousUrl,
  }

  if (params) {
    Object.keys(params).forEach((key) => {
      payload[key] = params[key]
    })
  }

  return ApiRequest.post(API_ADD_TRACKING, payload)
}
